import { getI18n } from 'react-i18next';
/* eslint-disable prefer-destructuring */
/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import axios, { AxiosRequestConfig } from 'axios';
import { store, AppDispatch } from 'store';
import { setUser, removeUser } from 'store/auth';
import { authApi } from 'api/auth';
import { showNotification } from 'config/toast';
import { getValidErrorMessage, normalize } from 'utils';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { setAuthCookie } from 'utils/api';
import { BASE_URL } from '../constants/api';
import { AUTH_COOKIE_PARAM, LINKS } from 'constants/constants';
import { localStorageService } from '../libs/localStorage/local.storage.service';
import { User } from 'types/auth';
import { getCookie } from 'utils/common';

const normalizeBody = (config: AxiosRequestConfig<any>): AxiosRequestConfig<any> => {
  /*
      By default Safari normalizes Unicode strings, and other browsers not.
      This creates weird behaviors when some value is saved to database from browser,
      and then it doesn't match the search string when server is trying to search for it in database in response for a browser request.
      This code is trying to figure out if passed data is going to be serialized into JSON, serializes it before Axios and enforces normalization for all browsers.
    */
  if (
    config.data &&
    !(config.data instanceof Blob) &&
    !(config.data instanceof FormData) &&
    !(config.data instanceof URLSearchParams) &&
    !(config.data instanceof ArrayBuffer) &&
    !(config.data instanceof Set) &&
    !(config.data instanceof Map) &&
    !(config.data instanceof File)
  ) {
    let payload = config.data;

    try {
      if (typeof config.data === 'string') {
        const parsed = JSON.parse(config.data);

        if (typeof parsed !== 'string') {
          payload = parsed;
        } else {
          payload = normalize(parsed);
        }
      }
    } catch {
      if (typeof config.data === 'string') {
        payload = normalize(config.data);
      }
    }

    config.data = typeof payload === 'string' ? payload : normalize(JSON.stringify(payload));

    if (!config.headers?.['Content-type']) {
      config.headers = config.headers || {};
      config.headers['Content-type'] = 'application/json';
    }
  }

  return config;
};

export const axiosApiInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 120000,
  timeoutErrorMessage: 'Timeout Error',
});

axiosApiInstance.interceptors.request.use(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore */
  async (config: AxiosRequestConfig<any>) => {
    if (typeof window !== 'undefined') {
      const authToken = getCookie(AUTH_COOKIE_PARAM);
      config.headers = config.headers || {};
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
    }

    return normalizeBody(config);
  },

  (error) => {
    return Promise.reject(error);
  }
);

// eslint-disable-next-line
const refreshAuthLogic = (failedRequest: any) =>
  authApi
    .refreshToken({ ...localStorageService.getTokens(), user: localStorageService.getUser() as User })
    .then(({ data }) => {
      const dispatch: AppDispatch = store.dispatch;
      dispatch(setUser(data.user));
      setAuthCookie(data.accessToken, data.user.role);
      failedRequest.response.config.headers.Authorization = `Bearer ${data.accessToken}`;
      return Promise.resolve();
    })
    .catch((error) => {
      const dispatch: AppDispatch = store.dispatch;

      dispatch(removeUser());
      setAuthCookie('', '');

      if (error?.request?.status !== 401 && failedRequest.response.config.shouldShowErrorNotification !== false) {
        showNotification(getValidErrorMessage(error), 'error');
      }
      return Promise.reject(error);
    });

createAuthRefreshInterceptor(axiosApiInstance, refreshAuthLogic, {
  statusCodes: [401],
});

axiosApiInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401 && localStorageService.getUser()) {
      localStorageService.removeUser();
      setAuthCookie('', '');
      localStorageService.removeJWTokens();
      return Promise.resolve();
    }
    return Promise.reject(error);
  }
);

export default axiosApiInstance;

export const axiosInstanceForCloudinaryApi = axios.create({
  timeoutErrorMessage: 'Timeout Error',
});

axiosInstanceForCloudinaryApi.interceptors.request.use(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore */
  async (config) => {
    config.headers = config.headers || {};
    return normalizeBody(config);
  },
  (error) => {
    return Promise.reject(error);
  }
);
